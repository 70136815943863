import { Env } from '@region-lib/env';
import { fetch } from '~/utils/request';
import { EmailCodeType } from '~/constants';

const { API_HOST } = Env;

const URL = {
  emailCodeSend: `${API_HOST}/user/public/v1/email-code-send`,
  bindEmailCodeSend: `${API_HOST}/user/private/v1/email-code-send`,
  emailBind: `${API_HOST}/user/private/v1/bind-email`,
  loginTg: `${API_HOST}/user/public/v1/login-tg`,
  emailLogin: `${API_HOST}/user/public/v1/login-email`,
  botId: `${API_HOST}/user/public/v1/tg-map`,
  profileUpdate: `${API_HOST}/user/private/v1/profile-update`,
  getAffiliateUrl: `${API_HOST}/api/affiliate_api/private/affiliate/exists-return`,
  getDomainWhiteList: `${API_HOST}/user/public/v1/trust-domain`
};

export const getAffiliateUrl = (data) => {
  return fetch({
    url: URL.getAffiliateUrl,
    method: 'GET',
    data
  });
};

export const postTgLogin = (data) => {
  return fetch({
    url: URL.loginTg,
    method: 'POST',
    data
  });
};

export const postEmailCodeSend = (data: {
  captcha_type: string;
  geetest_challenge: string;
  geetest_validate: string;
  geetest_seccode: string;
  email_type: EmailCodeType.login_code;
  email?: string;
  lang?: string;
}) => {
  return fetch({
    url: URL.emailCodeSend,
    method: 'POST',
    data
  });
};
export const postBindEmailCodeSend = (data: {
  captcha_type: string;
  geetest_challenge: string;
  geetest_validate: string;
  geetest_seccode: string;
  email_type: EmailCodeType.bind_2fa;
  email?: string;
  lang?: string;
}) => {
  return fetch({
    url: URL.bindEmailCodeSend,
    method: 'POST',
    data
  });
};

export const postBindEmail = (data) => {
  return fetch({
    url: URL.emailBind,
    method: 'POST',
    data
  });
};

export const postEmailLogin = (data: {
  email: string;
  email_code: string;
  referral_code?: string;
}) => {
  return fetch({
    url: URL.emailLogin,
    method: 'POST',
    data
  });
};

export const getBotId = (params) => {
  return fetch({
    url: URL.botId,
    method: 'GET',
    params
  });
};

export const postProfileUpdate = (data: {
  nick_name?: string;
  address?: string;
}) => {
  return fetch({
    url: URL.profileUpdate,
    method: 'POST',
    data
  });
};

export const getDomainWhiteList = () => {
  return fetch({
    url: URL.getDomainWhiteList,
    method: 'GET'
  });
};
