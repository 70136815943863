import { initBetterbitFrame } from '@better-bit-fe/betterbit-frame';
import { useUserInfo } from '@better-bit-fe/base-provider';
import { parseUrl, Env } from '@region-lib/env';
import { http } from '@better-bit-fe/base-utils';
const apiHost = Env.API_HOST;
const useFrame = (params: any) => {
  const { componentHeader, componentFooter } = initBetterbitFrame({
    type: 'none'
  });
  const { setUserInfo, userInfo } = useUserInfo();

  // 切换多语言
  const onLanguageChangeCallBack = (val) => {
    let newUrl;
    const langReg = /([a-z]{2}-[A-Z]{2})/;
    if (langReg.test(location.href)) {
      newUrl = location.href.replace(langReg, val);
    } else {
      newUrl = `${location.origin}/${val}${location.pathname}${location.search}`;
    }
    location.href = newUrl;
    localStorage.setItem('LANG_KEY', val);
  };
  // @ts-ignore
  const logout = async () => http.post(`${apiHost}/logout`); //退出登录
  const handleLogout = () => {
    logout();
    setUserInfo(); //重置用户信息
  };

  const handleLogin = (newInfo?: any) => {
    params?.handleLogin(newInfo);
  };

  componentHeader.then((res) => {
    const {
      user,
      loginChecked,
      setLang,
      assets,
      currencyInfo,
      showConnectDialog,
      showDepositDialog,
      showWithdrawDialog,
      onBeforeLanguageChange,
      onLanguageChange,
      onLogin,
      onLoginChecked,
      onUserChange,
      onLogout,
      onAssetChange,
      onCurrencyChange,
      onDexInfoChange
    } = res;
    setTimeout(() => {
      if (loginChecked.value) {
        handleLogin();
      }
    }, 0);
    onLanguageChange((lang) => {
      onLanguageChangeCallBack(lang);
    });
    onLogout(() => {
      handleLogout();
      window.location.reload();
    });
    onLogin((val, originVal) => {
      if (originVal) {
        handleLogin(originVal);
        setUserInfo(originVal);
      }
    });
    onUserChange((val) => {
      if (val) {
        handleLogin();
      }
    });
  });

  componentFooter.then((res) => {
    console.log('Footer Rendered');
  });
};

export default useFrame;
